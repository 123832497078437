.feature_container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 300px;
}
.feature-title {
  display: flex;
  margin-bottom: 1rem;
}
.feature-title h1 {
  margin: 0;
}
.bar {
  background: var(--gradient-bar);
  width: 5px;
  margin-right: 1.5rem;
}
.feature_container img {
  max-width: 300px;
  width: 100%;
  align-self: center;
}
@media screen and (max-width: 375px) {
  .feature_container img {
    max-width: 250px;
  }
}
.feature_container:hover {
  background-color: rgb(179, 186, 196, .1);
}
