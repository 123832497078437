.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-footer);
  font-family: var(--font-family);
}

.footer div {
  color: var(--color-text-light);
}

.footer-links {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer-links div {
  width: 250px;
  margin: 1rem;
}

.footer-links_wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.footer-links_wrapper h4 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 1rem;
}

.footer-links_wrapper p {
  margin: 0.5rem 0;
  cursor: pointer;
}

.footer-links_logo img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
}

.footer-social {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.footer-social a {
  margin: 0 1rem;
}

.footer-copyright {
  margin-top: 2rem;
  text-align: center;
}

.footer-links a:hover,
.footer-social a:hover {
  color: var(--color-subtext);
}
