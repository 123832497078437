@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Playfair+Display:wght@400;800&display=swap");

:root {
  --font-family: "Josefin Sans", sans-serif;
  --font-family-secondary: "Playfair Display", serif;

  --gradient-text: linear-gradient(90deg, #ae67fa 0%, #69acf0 100%);
  --gradient-bar: linear-gradient(100deg, #69acf0 0%, #ae67fa 100%);
  --text-highlight_1: #AC69FA;
  --text-highlight_2: #7C72F4;

  --color-bg: #F6F6F6;
  --color-footer: #080032;
  --color-blog: #080032;
  --color-text-light: #7B899B;
  --color-text-mid: #555357;
  --color-text: #2D2C2E;
  --color-subtext: #BFA5B8;
  --color-link: #F3C04B;
}
