.features {
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 800;
  background: var(--color-bg);
  position: relative;
}
.features_container {
  display: flex;
  margin: 3rem 0;
  flex-wrap: wrap;
  justify-content: space-around;
}
.feature_container {
  margin: 1rem;
  /* flex: 0 1 30%; */
}
.features-skills_container {
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  font-size: 26px;
}
.features-skills_container div {
  color: var(--color-text);
  opacity: 0.5;
  text-align: center;
  width: 100px;
}
.features-skills_container > div > p {
  font-size: 20px;
}
@media screen and (max-width: 750px) {
  .features_container {
    flex-direction: column;
    align-items: center;
  }
  .feature_container {
    margin: 0 0 2rem 0;
  }
}
@media screen and (min-width: 1024px) {
  .features-skills_container div {
    width: 150px;
  }
}
