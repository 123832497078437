.thumbnails {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.thumbnails .image {
  flex: 1;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thumbnails .image img {
  width: 100%;
  height: 100%;
  max-width: 200px;
}
@media screen and (max-width: 550px) {
  .thumbnails .image {
    margin: 1rem 0;
  }
}
