.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 6rem;
  height: 170px;
}
.navbar-links {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar-links_logo {
  margin-right: 2rem;
}
.navbar-links_logo img {
  width: 150px;
}

.navbar-links_container {
  display: flex;
  flex-direction: row;
}

.navbar-links_container p {
  color: var(--color-text);
}
.navbar-menu_container p {
  color: var(--color-bg);
}
.navbar-links_container p,
.navbar-menu_container p {
  
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}

.navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.navbar-menu svg {
  cursor: pointer;
}

.navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  z-index: 900;
  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  top: 20px;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.navbar-menu_container p {
  margin: 1rem 0;
}

@media screen and (max-width: 1050px) {
  .navbar-links_container {
    display: none;
  }
  .navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .navbar {
    padding: 2rem;
  }
}
