.header {
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 170px); */
}

.header-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 5rem;
  font-family: var(--font-family);
}

.header-content h1 {
  font-weight: 800;
  font-size: 70px;
  line-height: 80px;
  margin-bottom: 5rem;
}

.header-content h2 {
  font-size: 28px;
  font-weight: 500;
}

.header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .header-image img {
  width: 100%;
  max-width: 650px;
} */

.main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* position: relative; */
}
.main .title {
  flex: 1;
}
.main img {
  max-width: 100%;
  max-height: 600px;
}
.main {
  display: flex;
  flex-direction: row;
}
.main .title p {
  font-size: 4.5rem;
  margin-bottom: 0;
  color: var(--color-text-mid);
}
.main .title .pop {
 font-size: 7rem;
}
.main .subtitle {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
} 
.main .subtitle p {
  font-size: 2rem;
  flex: 1;
} 
.main .subtitle button {
  background: var(--color-link);
  border-radius: 10px;
  margin-left:0;
  margin-top: 3rem;
  align-self: flex-start;
  font-size: 2rem;
  color: var(--color-bg);
} 
.main .subtitle button:hover {
  color: var(--color-text)
}
.serif {
  font-family: var(--font-family-secondary);
}
.main .graphic {
  flex: 1;
  display: flex;
  justify-content: center;
}
.main .title .withBg {
  background-color: #C1D7FF;
  /* color: var(--color-bg); */
  margin-top: 1.5rem;
  padding: 1.5rem;
  font-size: 1.5rem;
  font-family: var(--font-family-secondary);
  border-radius: 20px;
}


@media screen and (max-width: 1050px) {
  .header {
    flex-direction: column;
  }
  .header-content {
    margin: 0 0 3rem;
  }
  .main {
    flex-direction: column;
  }
}

@media screen and (max-width: 650px) {
  .header h1 {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 3rem;
  }
  .header h2 {
    font-size: 24px;
  }
  .main .title .pop {
    font-size: 5.5rem;
  }
}

@media screen and (max-width: 490px) {
  .header h1 {
    font-size: 36px;
    line-height: 48px;
  }
  .header h2 {
    font-size: 20px;
  }
  .main .title p {
    font-size: 3.5rem;
  }
  .main .title .pop {
    font-size: 4rem;
    margin: 1rem 0;
  }
  .main .subtitle p {
    font-size: 1.5rem;
  } 
}

