div {
  color: var(--color-text);
  font-family: var(--font-family);
}
h1 {
  margin-bottom: 2rem;
}
h3,
p {
  margin-bottom: 1rem;
}
.portfolio {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.image {
  flex: 1;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image img {
  width: 100%;
  height: 100%;
  min-width: 240px;
  width: 500px;
}
@media screen and (max-width: 600px) {
  .image {
    margin: 1rem 0;
  }
  .image img {
    max-width: 450px;
  }
}
@media screen and (max-width: 500px) {
  .image img {
    max-width: 350px;
  }
}
@media screen and (max-width: 375px) {
  .image img {
    max-width: 250px;
  }
}
