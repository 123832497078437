/* nav {
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

section {
    padding: 20px;
    margin-top: 20px;
}

.image-container img {
    margin: 5px;
    width: calc(25% - 10px); /* Adjust based on how many images per row you want 
    height: auto;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
} */

/* Header navigation styling */
nav {
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
}

nav a {
    color: black;
    text-decoration: none;
    padding: 10px 20px;
}

nav a:hover {
    background-color: #f0f0f0;
}

/* Section styling */
section {
    padding: 20px;
    margin-top: 10px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

/* Image container */
.image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.image-container img {
    margin: 5px;
    width: calc(100% / 4 - 10px); /* 3 images per row by default */
    height: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .image-container img {
        width: calc(100% / 2 - 10px); /* 2 images per row on tablets */
    }
}

@media (max-width: 480px) {
    nav {
        flex-direction: column;
        align-items: center;
    }

    nav a {
        padding: 10px;
        text-align: center;
        width: 100%; /* Full width links on mobile */
    }

    .image-container img {
        width: 100%; /* 1 image per row on mobile */
        margin: 5px 0; /* Adjust margin for mobile */
    }
}

/* Overlay styles */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.overlay img {
    max-width: 90%;
    max-height: 80%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-btn {
    position: fixed;
    top: calc(60px + 20px); /* Adjust this value based on your navigation's height */
    right: 20px;
    font-size: 30px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    opacity: 0.8;
    padding: 5px 10px;
    border-radius: 5px; /* Circular background */
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent background for better visibility */
}

.close-btn:hover {
    opacity: 1; /* Full opacity on hover for better visibility */
    background-color: rgba(0, 0, 0, 0.6); /* Darker background on hover */
}

.my-masonry-grid {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your images (optional: set object-fit) */
  .my-masonry-grid_column > div {
    margin-bottom: 30px;
  }
  
  .my-masonry-grid_column img {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px;
  }
  