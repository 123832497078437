.cta {
  display: flex;
}
.cta a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--color-text-mid);
  padding: 1rem;
  border: 5px solid transparent;
  border-image: var(--gradient-bar) 1;
}
.cta a:hover {
  color: var(--color-link);
}
.cta-phone {
  border-width: 5px;
  border-style: dashed;
  border-color: var(--color-link);
  color: var(--color-text-mid);
  max-width: 450px;
  padding: 1rem;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}
.cta-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.cta-block .cta-gap {
  font-size: 1.5rem;
  font-family: var(--font-family-secondary);
}
@media screen and (max-width: 550px) {
  .cta a {
    letter-spacing: normal;
    padding: 0.5rem;
    font-size: 16px;
  }
}
@media screen and (max-width: 650px) {
  .cta a {
    font-size: 20px;
  }
}
form {
  display: flex;
  margin-top: 2em;
}
input {
  padding: 0.75rem 1rem;
  line-height: 1.5;
  width: 100%;
  margin: 0px;
  background-color: transparent;
  border: 1px solid transparent;
  border-image: var(--gradient-bar) 1;
  font-size: 1.2em;
  color: var(--color-bg);
}
button {
  background: var(--gradient-bar);
  margin-left: 0.5rem;
  padding: 0.75rem 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
  text-decoration: none;
  transition: ease-out 0.14s;
  font-size: 1.2em;
  border: none;
}
button:hover {
  color: var(--color-bg);
}
