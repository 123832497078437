.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: var(--font-family);
}
.contact-subtitle {
  margin: 2rem;
}
.contact .section-title {
  text-align: center;
}
@media screen and (max-width: 550px) {
  .contact-subtitle {
    margin: 1rem 0;
    font-size: 20px;
  }
}
