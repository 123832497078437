.for-clients {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.for-clients h1 {
  font-size: 4rem;
  align-self: center;
  margin-bottom: 5rem;
}
.for-clients .preschool h1 {
  color: var(--text-highlight_1);
}
.for-clients .adult h1 {
  color: var(--text-highlight_2);
}
.for-clients .container {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.for-clients .container .details {
  flex: 1;
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 5rem;
  flex-wrap: wrap;
}
.for-clients .container .details img {
  max-width: 45%;
  height: auto;
  min-width: 350px;
}

.for-clients .adult {
  margin-top: 10rem;
}

.for-clients .places-box {
  margin-top: 5rem;
}
.for-clients .places-box h2 {
  font-size: 2.5rem;
  color: var(--text-highlight_1);
  font-weight: 500;
}
.for-clients .places-box .places {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.for-clients .places-box .places img {
  max-height: 120px;
}

@media screen and (max-width: 915px) {
  .for-clients .container .details img {
    max-width: 100%;
  }
}
@media screen and (max-width: 490px) {
  .for-clients h1 {
    font-size: 3rem;
  }
  .for-clients .container .details img {
    min-width: 300px;
  }
}
