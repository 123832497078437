.about {
  display: flex;
  /* background: var(--color-blog); */
  flex-direction: column;
  align-items: center;
  font-family: var(--font-family);
}
.about_wrapper {
  flex: 1;
  display: flex;
}
.about-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 5rem;
}

.about-content p {
  font-family: var(--font-family);
  font-size: 26px;
  line-height: 40px;
  color: var(--color-text);
}
.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-image img {
  width: 100%;
  max-width: 400px;
}

@media screen and (max-width: 1050px) {
  .about {
    flex-direction: column;
  }
  .about_wrapper {
    flex-direction: column;
  }
  .about-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .about p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .about p {
    font-size: 14px;
    line-height: 24px;
  }
}

.about {
  background-image: url("../../assets/wavy.png");
}
.story-container {
  display: flex;
  flex-wrap: wrap; /* Allows for wrapping on smaller screens */
  justify-content: space-around;
  padding: 20px;
  gap: 20px;
}

.story-event {
  flex: 1 1 200px; /* Flex-grow, flex-shrink, and flex-basis */
  max-width: 200px; /* Maximum width of each box */
  border: 0.75px dashed transparent;
  border-image: var(--gradient-bar) 1;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  background-color: var(--color-bg);
}

.story-icon {
  font-size: 56px; /* Adjust size as needed */
  margin-bottom: 10px;
  color: var(--text-highlight_2);
}

.story-event h3 {
  margin: 10px 0;
  color: var(--text-highlight_1);
}

.story-event p {
  color: var(--color-text-mid);
  font-family: var(--font-family-secondary);
  line-height: 1.4;
}
.story-event:last-child {
  box-shadow: 0 0 8px 2px rgba(244, 172, 231, 0.8);
}
